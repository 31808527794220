// // card styles
.card {
	border: none;
	@include r(0);
	background-color: transparent;
	@include max767 {
		max-width: 400px;
		width: 100%; }
	p {
		font-size: 18px;
		line-height: 1.5; }
	.owl {
		img {
			display: block;
			margin: auto;
			height: auto;
			max-width: 100%;
			max-height: 300px; } } }

.article-card {}

.card-overlay {
	padding-top: 15px;
	padding-bottom: 40px;
	background-color: transparent;
	@include max1199 {
		padding-bottom: 20px; }
	@include max991 {
		padding-top: 0;
		padding-bottom: 15px; } }


.report-card {
	margin-bottom: 30px;
	background-color: #fff;
	border: 1px solid #dedede;
	height: auto;
	position: relative;
	@include mx(auto);
	h5 {
		padding: 15px; }
	a.link {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center; }
	.img-fluid {
		margin-top: 15px; } }

a.report-card {
	@include tm;
	@include h {
		cursor: pointer;
		.link {
			text-decoration: underline; }
		h5 {
			text-decoration: underline; } } }



.report-detail-card {
	margin-bottom: 30px;
	background-color: #fff;
	border: 1px solid #dedede;
	@include mx(auto);
	h5 {
		padding: 15px; } }

a.report-detail-card {
	@include tm;
	@include h {
		cursor: pointer;
		.link {
			text-decoration: underline; }
		h5 {
			text-decoration: underline; } } }


.report-info {
	padding: 15px; }

.report-info-small {
	padding: 15px;
	font-size: 15px; }

.article-card {
	@include mx(auto);
	.title {
		padding-left: 10px;
		@include max991 {
			padding-left: 0; } } }

.article-card {}

.card-photo {
	position: relative;
	height: 0;
	padding-top: 100%;
	overflow: hidden;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		@include tf(translate(-50%, -50%));
		display: block;
		max-width: 100%;
		max-height: 100%;
		@include mx(auto); } }

.card-typo-photo {
	width: 100%;
	display: block;
	height: 0;
	padding-bottom: 100%;
	position: relative;
	z-index: 0;
	overflow: hidden;
	.typo-img {
		position: absolute;
		z-index: 1;
		display: block;
		top: 50%;
		left: 50%;
		max-width: 100%;
		max-height: 100%;
		@include tf(translate(-50%, -50%)); } }

.single-photo {
	height: 360px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	.single-img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		@include mx(auto); } }

.report-photo {
	padding: 0;
	margin: 15px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	.report-img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		@include mx(auto);
		padding: 5px;
		border: 1px solid #dedede; } }

.company-card {
	margin-bottom: 30px;
	background-color: #fff;
	border: 1px solid #dedede;
	height: 360px;
	position: relative;
	@include mx(auto);
	h5 {
		padding: 15px; }
	a.link {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center; } }

a.company-card {
	@include tm;
	@include h {
		cursor: pointer;
		.link {
			text-decoration: underline; }
		h5 {
			text-decoration: underline; } } }


.company-detail-card {
	margin-bottom: 30px;
	background-color: #fff;
	border: 1px solid #dedede;
	@include mx(auto);
	h5 {
		padding: 15px; } }

a.company-detail-card {
	@include tm;
	@include h {
		cursor: pointer;
		.link {
			text-decoration: underline; }
		h5 {
			text-decoration: underline; } } }

.card-columns {
	@include media-breakpoint-only(sm) {
		column-count: 2; }
	@include media-breakpoint-only(md) {
		column-count: 2; } }
