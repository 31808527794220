.pagination-wrap {}

.pagination {}


.page-link {
	color: $bordo;
	color: $gold;
	@include fz(16);
	@include r(40px);
	@include r(0);
	@include mx(5px);
	min-width: 40px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	border-color: transparent;
	@extend .pagination-shadow;
	@include max767 {
		@include mx(3px); }
	@include h {
		background-color: rgba($gold, 0.1);
		border-color: transparent;
		color: $dark-text;
		outline: none; }
	@include fc {
		background-color: rgba($gold, 0.2);
		border-color: transparent;
		color: $dark-text;
		outline: none; }
	&.active {
		color: $dark-text;
		background-color: rgba($gold, 0.2); }
	&.disabled {
		color: $grey;
		@include h {
			color: $grey;
			background-color: transparent;
			border-color: transparent; }
		@include fc {
			color: $grey;
			background-color: transparent;
			border-color: transparent; } } }


.page-item {
	@include f {
		.page-link {
			@include r(40px);
			@include r(0); } }
	@include l {
		.page-link {
			@include r(40px);
			@include r(0); } } }

.global-link {}
