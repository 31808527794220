@import "variables";
@import "mixins";

// framework
@import "vendor/bootstrap/bootstrap.scss";
@import "vendor/owl/owl.carousel.scss";

// global
@import "font.scss";
@import "global";

// website comonents
@import "components/_submenu.sass";
@import "components/articles.sass";
@import "components/buttons.sass";
@import "components/card.sass";
@import "components/colors.sass";
@import "components/contact.sass";
@import "components/fish-report.sass";
@import "components/footer.sass";
@import "components/form.sass";
@import "components/gallery.sass";
@import "components/gap.sass";
@import "components/header.sass";
@import "components/introduction.sass";
@import "components/list-style.sass";
@import "components/map.sass";
@import "components/nav-panel.sass";
@import "components/owl.sass";
@import "components/pagination.sass";
@import "components/prefooter.sass";
@import "components/report.sass";
@import "components/sign-up.sass";
@import "components/typo.sass";
