body {
	position: relative;
	margin-right: auto;
	margin-left: auto;
	overflow-x: hidden;
	font-family: $font-main;
	@include fz(18);
	height: 1.2em;
	color: $color-main; }

html,
body {
	min-height: 100vh;
	position: relative; }

@include selection($selection-color);


.shadow-box {
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3); }

.shadow-light {
	box-shadow: 0px 0px 38px 1px rgba(0, 0, 0, 0.3);
	@include max1365 {
		box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2); } }

.shadow-content {
	box-shadow: 0px 0px 35px 1px rgba(0, 0, 0, 0.4); }

.pagination-shadow {
	box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2); }

.grow-same {
	flex-grow: 1; }

.clearfix {
	position: relative;
	@include a {
		@include ct;
		clear: both; } }

.page-wrap {
	background-image: url(../img/tpl/page-bg.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	position: relative;
	@include a {
		@include ct;
		position: absolute;
		z-index: 1;
		height: 900px;
		bottom: 0;
		right: 0;
		left: 0;
		background: linear-gradient(rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1)); }
	> .transparent-bg {
		position: relative;
		z-index: 2;
		@include py(35px);
		@include max1199 {
			@include py(20px); } } }

.transparent-bg {
	background-color: rgba($white, 0.95s); }

.rainbow-bg {
	background-color: $white;
	background-image: url(../img/tpl/rainbow.jpg);
	background-position: top right;
	background-size: auto;
	background-repeat: repeat-y;
	position: relative;
	z-index: 0;
	@include max1199 {
		background-size: 50% auto; }
	@include max991 {
		background-size: 55% auto; }
	@include max767 {
		background-size: 100% auto;
		position: relative;
		.content {
			position: relative;
			z-index: 2;
			background-color: rgba(255, 255, 255, 0.6);
			@include px(15px);
			@include py(15px); } } }



.header,
.footer,
.rainbow-bg {
	position: relative;
	@include b {
		@include ct;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 25px;
		background: linear-gradient(rgba(0, 0, 0, 0.2) 2%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05), rgba(255, 255, 255, 0)); }
	.container,
	.content {
		position: relative;
		z-index: 3; } }

.ovh {
	overflow: hidden; }

.tooltip > .tooltip-inner {
	background-color: $gold;
	color: $black;
	display: block;
	white-space: nowrap;
	z-index: 15; }

.tooltip {
	.arrow {
		@include b {
			border-left-color: $gold; } } }

.bs-tooltip-left {
	.arrow {
		@include b {
			position: absolute;
			bottom: 5px; } } }

