// // font main
$font-main: 'Roboto', sans-serif;



// main color theme
$color-main:		#333;
$color-light:		#666;
$grey-dark:			#999;


$violet-bg: 		#150728;

$grey:				#c6c6c6;
$white: 			#fff;
$black: 			#000;
$bordo:				#450202;

$gold: 				#a59335;
$dark-text:			#150728;
$arrow-bg:			#dd952a;
$violet:			#450048;

$selection-color:	#dd952a;
$footer-nav:		#2d1508;

$primary:			#2b3990;
$info:				#084e84;
$danger:			#993333;
$success: 			#106b6e;
$green:				#0d6c52;

$primary-dark: 		#4b6aad;


// grid styles
$container-width: 1170px;
$grid-gutter-desctop: 60px;
$grid-gutter-tablet: 40px;
$grid-gutter-mob-width: 30px;

$content-width: $container-width + $grid-gutter-desctop;

// Animations Time
$transition-time: 0.3s;

$shadow: 			#000101;
