.prefooter {
	padding-top: 38px;
	padding-bottom: 25px;
	background-color: $violet-bg;
	color: $white;
	a {
		color: $white;
		@include h {
			color: $white; } } }

.about {
	flex: 0 0 345px;
	@include max1199 {
		flex: 0 0 260px; }
	@include max991 {
		width: 100%;
		flex: 0 0 100%;
		margin-bottom: 40px; }
	p, a {
		@include max991 {
			@include fz(14); }
		@include max767 {
			@include fz(16); } } }

.aditional-info {
	@include max991 {
		flex: 0 0 100%;
		width: 100%;
		@include max991 {
			justify-content: space-between; } }
	.flex-column {
		max-width: 260px;
		padding-left: 20px;
		min-width: 180px;
		@include max1199 {
			min-width: 1px;
			padding-left: 35px; }
		@include max991 {
			max-width: 300px;
			padding-left: 50px;
			@include f {
				padding-left: 0; } }
		@include max767 {
			width: 100%;
			padding-left: 0;
			max-width: 100%;
			margin-bottom: 30px; } }
	p, a {
		@include helvetica;
		@include fz(12);
		@include fw(500);
		@include max991 {
			@include fz(14); }
		@include max767 {
			@include fz(16); } }
	p {
		letter-spacing: -0.01em; } }
