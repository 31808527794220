// heading styles
@include hd {
	margin-top: 0;
	margin-bottom: 0px;
	@include roboto; }

h2 {
	color: $success;
	@include fz(48);
	@include fw(900);
	font-style: italic;
	@include max1199 {
		@include fz(44); }
	@include max991 {
		@include fz(36);
		letter-spacing: -0.02em; }
	@include max767 {
		@include fz(38); }
	&.large {
		@include fz(48);
		color: $black;
		@include max1199 {
			@include fz(44); }
		@include max767 {
			@include fz(38); } } }

h3 {
	@include fz(36);
	@include fw(900);
	@include max767 {
		@include fz(30); } }

h4 {
	@include fz(30);
	@include fw(900);
	font-style: italic;
	line-height: 1.2; }

h5 {
	@include fz(24);
	@include fw(700);
	color: $bordo;
	letter-spacing: 0.03em;
	&.small {
		color: $green;
		@include fz(20);
		@include fw(400);
		font-style: italic; } }

.report-smaller {
	h5 {
		@include max991 {
			@include fz(22); } } }

h6 {
	@include fz(14);
	@include fw(700); }

p {
	@include fz(12);
	line-height: 1.2;
	@include roboto;
	@include fw(400);
	&.middle {
		@include fz(16); }
	&.large {
		@include fz(18); }
	&.extralarge {
		@include fz(21);
		line-height: 1.35; }
	&.author {
		@include fz(18);
		@include fw(400);
		@include roboto;
		font-style: italic;
		color: $green;
		margin-bottom: 0;
		letter-spacing: 0.06em; }
	&.post-time {
		@include fz(14);
		color: $color-main;
		letter-spacing: 0.06em;
		font-style: italic; }
	&.report-text,
	&.lh1-5 {
		line-height: 1.5; }
	.link {
		@include fz(12);
		margin-right: 5px; } }

.photo2credits {
	margin-top: 10px;
	span {
		@include fw(400);
		font-style: italic;
		color: $bordo; } }

.prefooter {
	p {
		line-height: 1.5; } }

.ttu {
	text-transform: uppercase; }

.font-normal {
	font-style: normal; }



