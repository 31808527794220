// // footer
.footer {
	background-color: $white;
	.logo {
		margin-left: 50px;
		@include max1199 {
			margin-left: 40px; }
		@include max767 {
			margin-left: 0;
			margin-bottom: 30px; } } }

.py-footer {
	padding-top: 15px;
	padding-bottom: 7px;
	@include max767 {
		padding-bottom: 50px; } }

.footer-info {
	@include fz(14);
	@include roboto;
	@include fw(500);
	color: $white;
	letter-spacing: -0.01em;
	margin-bottom: 10px;
	@include max767 {
		color: $black; } }

.copy {
	margin-bottom: 0; }

.go-up-wrap {
	position: absolute;
	display: flex;
	justify-content: bottom;
	align-items: bottom;
	bottom: 0;
	right: 0; }


