.nav-panel-wrap {
	background-color: $white;
	position: relative;
	z-index: 2; }

.social-nav {
	margin-bottom: 0;
	height: 100%; }

.social-item {
	display: flex;
	@include px(8px);
	height: 100%;
	@include fz(24);
	@include tm;
	@include h {
		text-decoration: none;
		@include tm;
		.gold-icon {
			color: $violet-bg; } } }

.footer-panel-wrap {
	position: relative;
	z-index: 5;
	.social-nav {
		margin-right: 40px; }
	.social-item {
		padding-top: 10px;
		padding-bottom: 15px;
		@include px(8px);
		.fa {
			@include tm; }
		@include h {
			.fa {
				color: $gold;
				@include tm; } } } }

.footer-panel-wrap {
	background-color: $footer-nav;
	color: $white;
	@include py(15px); }
