.white,
a.white {
	color: $white;
	@include h {
		color: $white; } }

.gold,
a.gold {
	color: $gold;
	@include h {
		color: $gold; } }


.violet,
a.violet {
	color: $violet;
	@include h {
		color: $violet; } }


