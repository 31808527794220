// button
.btn {
	display: inline-block;
	padding: 8px 13px;
	cursor: pointer;
	border: none;
	@include r(0);
	@include fz(30);
	@include fw(900);
	line-height: 1.2;
	font-style: italic;
	@include roboto;
	text-align: center;
	@include tm;
	@include max1199 {
		@include fz(26); }
	@include max1199 {
		@include fz(22); }
	@include h {
		@include tm; } }

button {
	@include r(0);
	border: none;
	background-color: transparent; }

.transparent-btn {
	@include py(13px);
	background-color: rgba($white, 0.7);
	color: $danger;
	@extend .shadow-box;
	@include tm;
	@include h {
		background-color: $danger;
		color: rgba($white, 0.7);
		@include tm; } }

.btn-go {
	background-color: $violet-bg;
	color: $white;
	@include px(45px);
	position: relative;
	padding-top: 6px;
	padding-bottom: 10px;
	@include b {
		@include ct;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 45px;
		height: 100%;
		-webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
		clip-path: polygon(0 0, 100% 50%, 0 100%);
		background-color: $arrow-bg;
		@include tm;
		@include max991 {
			width: 25px; } }

	@include h {
		color: $white;
		background-color: $arrow-bg;
		@include b {
			background-color: $violet-bg;
			@include tm; } }
	@include fc {
		outline: none;
		box-shadow: none; } }

.transparent-small,
.btn-go-small {
	@include fz(18);
	@include py(8px);
	@include fw(400); }

.btn-go-small {
	padding-left: 60px; }

.link {
	display: inline-block;
	@include fz(18);
	@include roboto;
	color: $green;
	letter-spacing: 0.04em;
	@include h {
		color: $green; }
	&.link-gold {
		text-transform: uppercase;
		@include fz(14);
		color: $gold;
		@include fw(700);
		@include h {
			text-decoration: underline;
			color: $gold; } } }

.btn-up {
	display: block;
	position: relative;
	width: 60px;
	height: 18px;
	background-color: $footer-nav;
	text-align: center;
	color: $white;
	@include fz(22);
	margin: 0 auto;
	padding: 0;
	animation: up-arrow 2s infinite;
	@include keys(up-arrow) {
		0% {
			height: 18px; }
		50% {
			height: 25px; }
		100% {
			height: 18px; } }
	@include b {
		@include ct;
		position: absolute;
		width: 0;
		height: 0;
		bottom: 100%;
		left: 0;
		border-right: 30px solid transparent;
		border-left: 30px solid transparent;
		border-bottom: 13px solid $footer-nav; }
	@include h {
		color: $white;
		text-decoration: none; }
	span {
		position: relative;
		z-index: 5;
		display: block;
		letter-spacing: 0.04em; } }

.video-play {
	span {
		color: $green; }
	@include h {
		span {
			text-decoration: underline;
			color: $bordo; }
		.fa {
			text-decoration: none; } } }

.search-btn-wrap {
	.btn-search {
		@include fz(20);
		white-space: nowrap;
		background-color: $danger;
		color: rgba($white, 0.7);
		@include fw(400);
		@include px(20px);
		@extend .shadow-box;
		@include tm;
		@include h {
			background-color: $footer-nav;
			color: $white;
			@include tm; } } }

.report-smaller {
	.link,
	.video-play {
		@include min768-max991 {
			flex: 0 0 100%; } } }





