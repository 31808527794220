.sign-up {
	background-color: transparent;
	background-image: url(../img/home/sign-bg.png);
	background-size: cover;
	background-position: center center;
	position: relative;
	padding-left: 240px;
	padding-right: 55px;
	@include py(32px);
	@include max1199 {
		padding-right: 25px;
		padding-left: 180px; }
	@include max767 {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 100px; } }


.title-label {
	width: 215px;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: left;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	padding-left: 30px;
	padding-right: 60px;
	@include py(30px);
	@include max1199 {
		width: 160px;
		padding-left: 15px;
		padding-right: 20px; }
	@include max767 {
		width: 100%;
		height: 80px;
		text-align: center;
		padding-bottom: 50px; }
	@include b {
		@include ct;
		position: absolute;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba($arrow-bg, 0.7);
		-webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
		clip-path: polygon(0 0, 100% 50%, 0 100%);
		@include max767 {
			top: 0;
			right: 0;
			left: 0;
			bottom: auto;
			-webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
			clip-path: polygon(0 0, 100% 0, 50% 100%); } }
	.label-content {
		position: relative;
		z-index: 2;
		color: $white;
		@include max767 {
			text-align: center;
			@include mx(auto); } }
	* {
		margin-bottom: 0; } }

.sign-up-box {
	padding-left: 35px;
	padding-right: 28px;
	@include py(18px);
	@include max1199 {
		padding-right: 20px;
		padding-left: 20px; } }


.sign-form {
	flex: 0 0 44%;
	margin-left: 20px;
	padding-top: 10px;
	@include max991 {
		margin-left: 0;
		flex: 0 0 100%; } }




