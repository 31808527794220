// header
.header {
	background-color: $violet-bg;
	position: relative;
	z-index: 0; }

.logo {
	display: block;
	margin-left: 40px;
	@include max991 {
		max-width: 300px;
		margin-left: 0; }
	@include max479 {
		max-width: 180px; }
	img {
		display: block;
		max-width: 100%; }
	@include hf {
		outline: none;
		border: none;
		box-shadow: none; } }

.info {
	color: $white;
	@include helvetica;
	font-style: italic;
	@include fz(14);
	@include fw(500);
	letter-spacing: 0.03em;
	margin-bottom: 10px;
	@include max991 {
		margin-bottom: 5px; } }
