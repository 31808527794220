/*NAV STYLES*/
.submenu-wrap {
	position: relative!important;
	z-index: 100!important;
	background-color: $black;
	color: $white;
	background-size: initial;
	@include max991 {
		background-image: none; } }

.navbar {
	padding: 0; }

.navbar-expand-md,
.navbar-expand-md .navbar-collapse {
	@include min992 {
		display: block!important;
		width: 100%!important; } }

.collapse.show {
	display: block;
	width: 100%; }

.navbar-nav {
	position: relative;
	z-index: 100;
	display: flex;
	width: 85%;
	padding: 0;
	margin: 0 auto;
	justify-content: center;
	@include max1365 {
		width: 90%; }
	@include max991 {
		display: block!important;
		padding-bottom: 0!important; }
	@include max767 {
		width: 100%; }
	.nav-item {
		display: inline-flex;
		position: relative;
		align-items: center;
		justify-content: center;
		box-shadow: none;
		flex-grow: 1;
		.nav-link {
			position: relative;
			box-shadow: none;
			display: flex;
			align-items: center;
			width: 100%;
			text-align: center;
			@include f {
				box-shadow: none;
				outline: none; } }
		&.active {
			box-shadow: none!important;
			.nav-link {
				color: $white;
				@include h {
					@include max991 {
						background-color: transparent; } }

				@include b {
					width: 100%; } } }
		.caret {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			min-height: 20px;
			margin-left: 7px;
			margin-top: -2px;
			@include b {
				content: '';
				width: 0;
				height: 0;
				border-top: 6px solid $white;
				border-right: 6px solid transparent;
				border-left: 6px solid transparent;
				border-bottom: none;
				display: inline-block;
				@include fz(16);
				line-height: 1.2;
				position: absolute;
				top: 50%;
				margin-top: -2px;
				left: 0px;
				@include tm; } }
		@include hf {
			box-shadow: none!important;
			a {
				outline: none;
				box-shadow: none; }
			.caret {
				@include b {
					border-bottom: none;
					border-top: 6px solid $gold;
					@include tm; } } }
		@include max991 {
			display: block!important;
			text-align: left;
			&.show {
				background-color: rgba(yellow, 0.05);
				> a {
					background-color: rgba(yellow, 0.25);
					.caret {
						@include b {
							border-top-color: red; } } } }
			a {
				border-bottom: rgba(255, 255, 255, 0.4) 1px solid;
				&[aria-expanded="true"] {
					background-color: $black!important;
					color: $gold!important;
					.caret {
						@include b {
							@include tf(rotate(180deg));
							@include tm;
							margin-top: -13px; } } } } }
		> .nav-link {
			position: relative;
			display: block;
			font-weight: 700;
			padding: 15px 10px;
			color: $white;
			@include fz(14);
			line-height: 1.2;
			@include fw(600);
			font-family: "Montserrat", sans-serif;
			text-decoration: none;
			text-transform: uppercase;
			border: none;
			@include tm;
			cursor: pointer;
			i {
				margin-right: 8px; }
			.fa {
				@include max991 {
					@include fz(18);
					margin-right: 7px; } }
			@include max1199 {
				@include fz(12);
				@include px(5px);
				.caret {
					@include b {
						border-width: 4px; } } }
			@include max991 {
				@include fz(14);
				@include px(5px);
				.caret {
					@include b {
						border-width: 4px; } } }
			// +max992
			/// +fz(12)
			@include max991 {
				@include hover {
					background-color: transparent; }
				&[aria-expanded="true"] {
					.caret {
						@include b {
							margin-top: -2px; } } } } } }
	.dropdown-menu {
		position: absolute;
		display: block;
		left: 0;
		width: 190px;
		min-width: 1px;
		border-radius: 0;
		padding: 0;
		margin: 0;
		border: 1px solid transparent;
		box-shadow: 0px 0px 1px -1px springgreen;
		@include tm;
		top: 300%;
		opacity: 0;
		visibility: hidden;
		z-index: -50;
		background-color: transparent;
		@include max991 {
			position: static;
			width: 100%;
			left: 0;
			right: 0;
			margin: 0;
			top: 100%;
			display: none;
			transition: visibility 0s, opacity 0.5s linear; }
		a {
			position: relative;
			display: block;
			border-bottom: 1px solid $gold;
			padding: 10px 20px;
			@include fz(14);
			@include font-main;
			@include fw(400);
			letter-spacing: 0;
			text-transform: none;
			background-color: $black;
			color: $white;
			text-align: left;
			@include tm;
			cursor: pointer;
			@include max991 {
				background-color: transparent;
				color: rgba(255, 255, 255, 0.8);
				@include fz(14);
				padding-top: 15px;
				padding-bottom: 15px;
				background-color: lighten($black, 5%);
				text-align: center;
				@include hover {
					background-color: lighten($black, 5%); } }
			@include h {
				text-decoration: none;
				background: $gold;
				color: #000;
				@include max991 {
					background-color: lighten($black, 5%);
					color: $white;
					@include a {
						border-left-color: #000; } } } }
		.dropdown-submenu {
			> a {
				@include a {
					@include ct;
					position: absolute;
					display: block;
					width: 0;
					height: 0;
					border: 4px solid transparent;
					border-left-color: $white;
					top: 15px;
					right: 5px;
					margin: 0;
					@include transition(right 0.2s ease-out); } } }
		.dropdown-menu {
			left: calc(190px - 2px);
			top: 2px;
			@include tm;
			@include max991 {
				left: 0;
				right: 0; } }
		&.dropdown-menu-right {
			right: 0;
			left: auto;
			.dropdown-submenu {
				> a {
					@include a {
						right: auto;
						left: 5px;
						border-right-color: $white;
						border-left-color: transparent;
						@include transition(left 0.2s ease-out); } }
				> .dropdown-menu {
					left: auto;
					right: calc(190px - 2px);
					@include max991 {
						right: calc(130px + 5px); }
					@include max767 {
						right: 0;
						left: 0; } } } } }
	@include max991 {
		padding-top: 20px;
		padding-bottom: 20px; } }

.open {
	> a {
		outline: 0; }
	> .dropdown-menu {
		display: block; } }

.navbar-toggle {
	display: block;
	float: right;
	width: 60px;
	height: 50px;
	margin: 0 auto;
	padding: 10px 0;
	border: 1px solid transparent;
	border-radius: 5px;
	background-color: #fff;
	outline: none;
	cursor: pointer;
	@extend .shadow-light;
	&:focus {
		outline: none;
		border-color: $gold; }
	.icon-bar {
		display: block;
		height: 3px;
		width: 30px;
		margin: 4px auto;
		background-color: #000;
		@include tm; }
	&[aria-expanded="true"] {
		.icon-bar1 {
			margin-top: 4px;
			@include tf(rotate(135deg)); }
		.icon-bar2 {
			opacity: 0; }
		.icon-bar3 {
			margin-top: -13px;
			@include tf(rotate(-135deg)); } } }

.navbar-header {
	@include a {
		@include ct;
		clear: both; }
	@include min992 {
		display: none; }
	@include max991 {
		display: block;
		width: auto;
		padding-top: 10px;
		padding-bottom: 10px; } }

.mob-dev {
	.navbar-nav {
		.nav-item {
			&.show {
				> a {
					color: $gold;
					text-decoration: none;
					&[aria-expanded="true"] {
						> .caret {
							@include b {
								border-top-color: $gold;
								@include max991 {
									border-bottom-color: $gold; } } } }
					&[aria-expanded="false"] {
						> .caret {
							@include b {
								border-top-color: $gold!important;
								@include max991 {
									border-bottom-color: $gold; } } } } }
				> .dropdown-menu {
					top: calc(100% + 4px);
					opacity: 1;
					z-index: 100;
					visibility: visible;
					display: block; }
				>.caret {
					@include b {
 } } } }						// border-top-color: red
		.dropdown-menu {
			.dropdown-submenu {
				&.open {
					> a {
						text-decoration: none;
						background: $gold;
						color: $black;
						@include a {
							border-left-color: $white;
							z-index: 110;
							right: -10px;
							@include transition(right 0.2s ease-out);
							@include tf(rotate(180deg)); } }
					> .dropdown-menu {
						top: -1px;
						z-index: 100;
						opacity: 1;
						visibility: visible;
						@include tm;
						display: block; } }
				> a {
					@include max991 {
						@include tm;
						@include a {
							@include tf(rotate(90deg));
							@include tm;
							top: 50%;
							margin-top: -3px;
							border-left-color: rgba(255, 255, 255, 0.8); } } }
				@include max991 {
					&.open {
						> .dropdown-menu {
							display: block;
							a {
								padding-right: 20px;
								padding-left: 20px;
								background-color: lighten($black, 15%); }
							> li {
								a {
									@include hover {
										background-color: lighten($black, 20%); } } }
							> .dropdown-submenu {
								&.open {
									> a {
										background-color: lighten($black, 15%);
										color: #fff;
										@include hover {
											background-color: lighten($black, 15%);
											color: #fff; } }
									> .dropdown-menu {
										a {
											padding-right: 20px;
											padding-left: 20px;
											background-color: lighten($black, 25%);
											@include hover {
												background-color: lighten($black, 25%);
												color: #fff; } } } } } }
						> a {
							background-color: lighten($black, 10%);
							color: $white;
							@include tm;
							@include a {
								@include tf(rotate(270deg));
								right: 5px;
								@include tm;
								margin-top: -8px; } } } } }
			> a {
				@include h {
					background-color: rgba(255, 255, 255, 0.6); } }
			&.dropdown-menu-right {
				.dropdown-submenu {
					&.open {
						> a {
							@include a {
								left: -10px;
								right: auto;
								border-right-color: $white;
								border-left-color: transparent;
								@include transition(left 0.2s ease-out); } } }
					@include max991 {
						> a {
							@include tm;
							@include a {
								@include tf(rotate(-90deg));
								right: 5px;
								left: auto;
								border-right-color: $white;
								border-left-color: transparent;
								@include tm;
								top: 50%;
								margin-top: -3px; } }
						&.open {
							> a {
								@include tm;
								@include a {
									right: 5px;
									left: auto;
									@include tm;
									margin-top: -8px;
									@include tf(rotate(90deg)); } } } } } } } } }

.desctop-dev {
	.navbar-nav {
		.nav-item {
			@include hover {
				.caret {
					@include b {
						border-top-color: $gold; } } }
			> a {
				@include hover {
					// color: #3399cc
					text-decoration: none; } }
			@include min1200 {
				@include hover {
					> a {
						color: $gold;
						text-decoration: none; }
					> .dropdown-menu {
						top: 100%;
						opacity: 1;
						z-index: 100;
						visibility: visible; } } }
			@include max1199 {
				&.show {
					> a {
						color: red;
						text-decoration: none;
						.caret {
							@include b {
								border-top-color: red; } } }
					> .dropdown-menu {
						top: 100%;
						opacity: 1;
						visibility: visible;
						z-index: 100; } } }
			@include max991 {
				&.show {
					> .dropdown-menu {
						display: block; } } }
			.dropdown-menu {
				@include max991 {
					a {
						border-color: rgba(cyan, 0.4);
						@include f {
							outline: none; }
						@include h {
							background-color: rgba(yellow, 0.25);
							color: cyan; } } }
				.dropdown-submenu {
					@include min1200 {
						@include h {
							> a {
								text-decoration: none;
								background: $gold;
								color: #000;
								@include a {
									z-index: 110;
									border-left-color: $white;
									right: -10px;
									@include transition(right 0.2s ease-out);
									@include tf(rotate(180deg)); } }
							> .dropdown-menu {
								top: 0;
								z-index: 100;
								opacity: 1;
								visibility: visible;
								@include tm; } } }
					@include max1199 {
						&.open {
							> a {
								text-decoration: none;
								background: darken(cyan, 5%);
								color: darken(violet, 10%);
								@include a {
									border-left-color: #000;
									right: -5px;
									@include transition(right 0.2s ease-out);
									@include tf(rotate(180deg)); } }
							> .dropdown-menu {
								top: 0;
								z-index: 100;
								opacity: 1;
								visibility: visible;
								@include tm; } } }
					@include max991 {
						> a {
							@include tm;
							@include a {
								@include tf(rotate(90deg));
								@include tm;
								top: 50%;
								margin-top: -3px;
								border-left-color: #000; } }
						&.open {
							> .dropdown-menu {
								display: block;
								a {
									padding-right: 20px;
									padding-left: 20px;
									background-color: rgba(255, 255, 255, 0.10); }
								> li {
									a {
										@include hover {
											background-color: rgba(cyan, 0.25); } } }
								> .dropdown-submenu {
									&.open {
										> a {
											background-color: rgba(255, 255, 255, 0.3);
											@include hover {
												background-color: rgba(255, 255, 255, 0.3); } }
										> .dropdown-menu {
											a {
												padding-right: 20px;
												padding-left: 20px;
												background-color: rgba(255, 255, 255, 0.15);
												@include hover {
													background-color: rgba(cyan, 0.25); } } } } } }
							> a {
								background-color: rgba(yellow, 0.25);
								color: cyan;
								@include tm;
								@include a {
									@include tf(rotate(270deg));
									right: 5px;
									@include tm;
									margin-top: -8px; } } } } }
				&.dropdown-menu-right {
					.dropdown-submenu {
						@include min1200 {
							@include hover {
								> a {
									@include a {
										left: -10px;
										right: auto;
										border-right-color: $white;
										border-left-color: transparent;
										@include transition(left 0.2s ease-out); } } } }
						@include max1199 {
							&.open {
								> a {
									@include a {
										left: -5px;
										right: auto;
										border-right-color: #000;
										border-left-color: transparent;
										@include transition(left 0.2s ease-out); } } } }

						@include max991 {
							> a {
								@include tm;
								@include a {
									@include tf(rotate(-90deg));
									right: 5px;
									left: auto;
									border-right-color: #000;
									border-left-color: transparent;
									@include tm;
									top: 50%;
									margin-top: -3px; } }
							&.open {
								> a {
									@include tm;
									@include a {
										right: 5px;
										left: auto;
										@include tm;
										margin-top: -8px;
										@include tf(rotate(90deg)); } } } } } } } } } }

.dropdown-menu {
	li {
		@include l {
			>a {
				border-bottom: none; } } } }

.nav-icon {
	margin-right: 7px; }


.mob-dev,
.desctop-dev {
	.navbar-nav {
		.dropdown-menu {
			.dropdown-submenu {
				> a {
					@include a {
						@include max991 {
							position: static;
							float: none;
							margin-left: 5px;
							margin-top: 0!important;
							display: inline-block;
							vertical-align: middle; } } } } } } }



