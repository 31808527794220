// owl nav styles
.owl-carousel {
	position: relative; }

.fish-owl {
	margin-bottom: 60px; }

.owl-nav {
	.owl-prev,
	.owl-next {
		position: absolute;
		top: 0;
		display: block;
		width: 50px;
		height: 100%;
		@include b {
			@include ct;
			width: 0;
			height: 0;
			position: absolute;
			top: calc(50% - 13px);
			transform: translate(-50%, -50%);
			border-top: 55px solid transparent;
			border-bottom: 55px solid transparent;
			@include tm; } }
	.owl-prev {
		left: -50px;
		@include b {
			border-right: 24px solid $white;
			left: 19px; }
		@include h {
			@include b {
				border-right-color: #000;
				@include tm; } } }
	.owl-next {
		right: -50px;
		@include b {
			border-left: 24px solid $white;
			right: -1px; }
		@include h {
			@include b {
				border-left-color: #000;
				@include tm; } } } }

.owl-dots {
	position: absolute;
	right: 0;
	left: 0;
	padding: 0;
	margin: 0;
	height: auto;
	min-height: 1px;
	line-height: 1;
	text-align: center; }

.owl-dot {
	display: inline-block;
	position: relative;
	width: 10px;
	height: 10px;
	@include r(50%);
	line-height: 1;
	background-color: $white;
	@include mx(7px);
	border: 1px solid $black;
	@include b {
		@include ct;
		position: absolute;
		display: none;
		top: 50%;
		left: 50%;
		width: 14px;
		height: 14px;
		@include r(50%);
		@include tf(translate(-50%, -50%));
		background-color: $black; }
	&.active {
		@include b {
			display: block; } } }


