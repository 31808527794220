@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeueMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/HelveticaNeue-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaRoman';
    src: url('../fonts/HelveticaNeueLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


