.photo2wrapper {
	border: 1px solid #dedede;
	padding: 5px;
	margin-bottom: 30px; }

.gallery {
	display: block;
	margin-top: 10px; }

.img-thumbnail {
	background-color: transparent;
	@include r(0);
	border: none; }

.photo2comments {
	text-align: center;
	font-size: 14px;
	margin-top: 5px; }
