.map {
	padding-top: 0;
	padding-bottom: 0; }

.map-title {
	@include py(35px); }

.map-wrap {
	img {
		display: block;
		width: 100%; } }

#map-canvas {
	height: 300px;
	width: 100%; }
