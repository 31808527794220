// FORMS
.form-control,
input,
textarea {
	color: $dark-text;
	@include pht($color-light);
	line-height: 1.2;
	width: 100%;
	@include fc {
		box-shadow: 0 0 0 3px rgba(165, 147, 53, 0.4);
		border-color: $gold; } }

select {
	&.form-control {
		width: 100%;
		line-height: 1.2;
		height: auto;
		padding-top: 7px;
		padding-bottom: 5px;
		display: inline-block;
		-moz-appearance: window;
		-webkit-appearance: none;
		-moz-appearance: none; } }

.search-form {
	margin-bottom: 5px;
	@include max767 {
		justify-content: center; }
	.btn-search {
		color: $white;
		@include fz(26);
		@include px(7px);
		@include py(0);
		line-height: 1;
		@include h {
			color: $gold; } }
	.form-control {
		margin-bottom: 0;
		@include fz(14);
		padding-top: 8px;
		padding-bottom: 6px;
		@include max767 {
			padding-top: 10px;
			padding-bottom: 8px;
			@include fz(16); } } }

.form-control {
	border: 1px solid $grey;
	@include helvetica-roman;
	line-height: 1.2;
	padding-top: 7px;
	padding-bottom: 5px;
	@include px(10px);
	@include fz(16);
	margin-bottom: 16px;
	&.search-input {
		width: 300px;
		@include max991 {
			width: 250px; } } }

.search-term {
	width: 200px;
	@include max575 {
		width: 165px; }
	@include max479 {
		width: 150px; }
	select.form-control:not([size]):not([multiple]) option,
	select.form-control:not([size]):not([multiple]) {
		width: 100%;
		line-height: 1.2;
		height: auto;
		padding-top: 7px;
		padding-bottom: 5px;
		display: inline-block;
		vertical-align: bottom;
		height: 40px; }
	input {
		height: 40px; } }


.gallery-wrap {
	.search-btn-wrap {
		@include max479 {
			width: 100%;
			text-align: center; } }
	.btn-search {
		@include max479 {
			margin-top: 15px;
			margin-right: 20px; } } }
