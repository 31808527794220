.gold-icon {
	@include fz(24);
	color: $gold;
	line-height: 1.4;
	@include tm; }

.typo-list {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	li {
		position: relative;
		display: flex;
		padding-left: 1px;
		@include f {
			@include b {
				display: none; } }
		@include b {
			@include ct;
			position: absolute;
			top: 50%;
			height: 12px;
			bottom: 0;
			left: 0;
			width: 1px;
			@include tf(translate(0, -50%));
			background-color: $dark-text; } } }

.typo-item {
	@include fz(14);
	@include fw(500);
	line-height: 1.4;
	@include roboto;
	color: $dark-text;
	display: flex;
	align-items: center;
	letter-spacing: -0.02em;
	@include px(5px);
	@include tm;
	span {
		border-bottom: 1px solid transparent; }
	@include h {
		text-decoration: none;
		color: $gold;
		span {
			border-color: $gold;
			color: $gold; }
		.fa {
			text-decoration: none;
			color: $dark-text;
			@include tm; } }
	.fa {
		margin-right: 10px;
		@include fz(26); } }

.links-list {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: -2px;
	li {
		display: block;
		line-height: 1; }
	.link {
		@include helvetica;
		@include fz(12);
		@include fw(500);
		display: inline-block;
		@include max991 {
			@include fz(14); }
		@include max767 {
			@include fz(16); } } }

.partners {
	padding: 0;
	margin: 0;
	margin-right: 70px;
	@include max767 {
		margin-right: 0; }
	li {
		margin-left: 27px; }
	a {
		display: block;
		max-width: 100%; } }




