// margins
.mb0 {
	margin-bottom: 0; }

.mb5 {
	margin-bottom: 5px; }

.mb10 {
	margin-bottom: 10px; }

.mb15 {
	margin-bottom: 15px; }

.mb20 {
	margin-bottom: 20px; }

.mb25 {
	margin-bottom: 25px; }

.mb30 {
	margin-bottom: 30px; }

.mb35 {
	margin-bottom: 35px; }

.mb30 {
	margin-bottom: 30px; }

.mb40 {
	margin-bottom: 40px;
	@include max991 {
		margin-bottom: 30px; }
	@include max767 {
		margin-bottom: 25px; } }

.mb45 {
	margin-bottom: 45px;
	@include max767 {
		margin-bottom: 25px; } }

.mb50 {
	margin-bottom: 50px;
	@include max767 {
		margin-bottom: 30px; } }

.mb55 {
	margin-bottom: 55px;
	@include max767 {
		margin-bottom: 30px; } }

.mb60 {
	margin-bottom: 60px;
	@include max767 {
		margin-bottom: 40px; } }

.ml30 {
	margin-left: 30px;
	@include max767 {
		margin-left: 15px; } }

.ml10 {
	margin-left: 10px; }

.ml20 {
	margin-left: 20px; }

.px0 {
	@include px(0); }

.px15 {
	@include px(15px); }

.px20 {
	@include px(20px); }

.py35 {
	@include py(35px); }

.py10 {
	@include py(10px); }

.py15 {
	@include py(15px); }

.py25 {
	@include py(25px); }

.py27 {
	@include py(27px); }

.px-main {
	@include px($grid-gutter-desctop / 2);
	@media (max-width: 1199px) {
		@include px($grid-gutter-tablet / 2); }
	@media (max-width: 767px) {
		@include px($grid-gutter-mob-width / 2); } }

// main gap styles
.container {
	@include mx(auto);
	@include px($grid-gutter-desctop / 2);
	@media (min-width: 1200px) {
		width: 100%;
		max-width: $content-width; }
	@media (max-width: 1199px) {
		@include px($grid-gutter-tablet / 2); }
	@media (max-width: 767px) {
		@include px($grid-gutter-mob-width / 2); }
	@media (min-width: 320px) {
		width: 100%;
		max-width: $content-width; }
	&.footer-container {
		@media (min-width: 1365px) {
			@include px(0); } }
	&.px0 {
		@include px(0); }
	&.px15 {
		@include px(15px); } }


.row {
	@include mx(-$grid-gutter-desctop / 2);
	@media (max-width: 1199px) {
		@include mx(-$grid-gutter-tablet / 2); }
	@media (max-width: 767px) {
		@include mx(-$grid-gutter-mob-width / 2); } }

.col,
[class*="col-"] {
	@include px($grid-gutter-desctop / 2);
	@media (max-width: 1199px) {
		@include px($grid-gutter-tablet / 2); }
	@media (max-width: 767px) {
		@include px($grid-gutter-mob-width / 2); } }

.row-user {
	@include mx(-20px);
	@include max767 {
		@include mx(-15px); } }


.col-user {
	@include px(20px);
	@include max767 {
		@include px(15px); } }

